body {
  color: black;
  background: white;
  font-size: 12pt;
  font-family: serif;
}

a {
  color: black;
  text-decoration: none;
}

.table-wrapper {
  margin: 0;
}

figure.image.image-align-left {
  display: inline;
  float: left !important;
  margin: 0;
}

table {
  border: 2px gray solid;
  border-collapse: collapse;
  empty-cells: show;
}

td,
th {
  page-break-inside: avoid;
  padding: 2pt;
  border: 1px silver solid;
  border-width: 1px 1px 0 0;
}

li {
  margin-top: 0;
  padding-top: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

#appForUserToolbar,
#appForObjectToolbar,
.breadcrumbs,
.search,
.fullscreen-search-anchor__container,
.main-nav,
.sidebar,
.header__guide,
.search-gte-container,
.social-share-buttons,
.header__additional,
.page-footer__list,
.universal-popup-component {
  display: none;
}

.header__content > *:not(h1) {
  display: none;
}

#google_translate_element {
  display: none !important;
}

.contact-form {
  display: none;
}

.psmtc_mcdN {
  display: none !important;
}

.grecaptcha-badge {
  display: none !important;
}

.dev-info {
  display: none;
}